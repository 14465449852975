import { MENU_TOGGLE, SET_TOKEN, REMOVE_TOKEN } from './actionType'

const defaultStore = {
    menuToggle: false,
    token: localStorage.getItem('token') || ''
}

export default (state = defaultStore, action) => {
    switch (action.type) {
        case MENU_TOGGLE:
            return { ...state, menuToggle: !state.menuToggle }
        case SET_TOKEN:
            return { ...state, token: action.payload.token }
        default:
            return state
    }
}
